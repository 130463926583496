import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import RegForm from 'routes/home/RegForm';

import CModal from 'components/Modal';
import Button from 'components/Button';
import Pattern from 'components/Pattern';
import { LanguageContext } from 'components/LanguageContext';

import iconAffordability from 'assets/participation-fee/icon-affordability.svg';
import iconDrive from 'assets/participation-fee/icon-drive.svg';
import iconTeam from 'assets/participation-fee/icon-team.svg';
import iconTelemetry from 'assets/participation-fee/icon-telemetry.svg';
import iconTyres from 'assets/participation-fee/icon-tyres.svg';
import iconTent from 'assets/participation-fee/icon-tent.svg';

import ImageAffordability from 'assets/participation-fee/affordability.jpg';
import ImageDrive from 'assets/participation-fee/arrive-and-drive.jpg';
import ImageTeam from 'assets/participation-fee/team-of-mechanics.jpg';
import ImageTelemetry from 'assets/participation-fee/telemetry-data.jpg';
import ImageTyres from 'assets/participation-fee/wheels-and-tyres.jpg';
import ImageTent from 'assets/participation-fee/pit-tent.jpg';
// import EarlyBirdOffer from 'assets/participation-fee/early-bird-offer.svg';
// import EarlyBirdOfferBg from 'assets/participation-fee/early-bird-offer-bg.svg';

import background from 'assets/participation-fee/background.jpg';

const icons = [
	iconAffordability,
	iconDrive,
	iconTeam,
	iconTelemetry,
	iconTyres,
	iconTent
];

const images = [
	ImageAffordability,
	ImageDrive,
	ImageTeam,
	ImageTelemetry,
	ImageTyres,
	ImageTent
];

const ParticipationFee = () => {
	const {
		// language: { id: langId },
		dictionary: { participationFee }
	} = useContext(LanguageContext);

	const [active, setActive] = useState(0);

	return (
		<Section id="participant-fee">
			<Container fluid>
				<Row>
					<Col
						sm={12}
						lg={6}
						xl={5}
						className="p-5 position-relative"
					>
						{/* <Fade direction="zoom">
							<EarlyBird
								src={langId === 'en' ? EarlyBirdOffer : EarlyBirdOfferBg}
								alt="EarlyBirdOffer"
							/>
						</Fade> */}

						<Fade direction="left">
							<h2>{participationFee.title}</h2>

							<h3>{participationFee.total.amount}</h3>

							<h4>{participationFee.total.description}</h4>

							<h2 className="mb-4">
								{participationFee.subtitle}
							</h2>

							<p>{participationFee.content}</p>

							<List>
								{participationFee.features.map((feature, i) => (
									<li
										key={i}
										role="button"
										onClick={() => setActive(i)}
										className={
											active === i ? 'active' : undefined
										}
									>
										<Icon src={icons[i]} />

										<p>{feature.title}</p>

										<div>
											<h4>{feature.title}</h4>

											{feature.content.map((line, j) => (
												<p key={j}>{line}</p>
											))}
										</div>
									</li>
								))}
							</List>

							<div className="mt-4 mb-4 d-flex align-items-center justify-content-start">
								<CModal
									content={
										<RegForm testDriveOverwrite="A test drive only (€250 fee)" />
									}
									className="c-modal--reg"
								>
									<Button
										variant="primary"
										className="text-uppercase btn-sm btn--hover-shadow-black"
										onClick={() => {}}
									>
										{participationFee.testDrive.buttonText}
									</Button>
								</CModal>
							</div>
						</Fade>
					</Col>

					<Col
						sm={12}
						lg={6}
						xl={7}
						className={`p-0 col-images${
							active === -1 ? '' : ' is--blurred'
						}`}
						style={{
							backgroundImage: `url(${
								active === -1 ? background : images[active]
							})`
						}}
					></Col>
				</Row>

				<Pattern
					x="right"
					y="bottom"
					name="ParticipationFeeBottomRight"
				/>
			</Container>
		</Section>
	);
};

const Section = styled.section`
	position: relative;

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		background: rgba(0, 0, 0, 0.5);
	}

	h4 {
		margin-bottom: 1em;

		button {
			margin-top: 0 !important;
		}
	}

	.container-fluid {
		position: relative;
		z-index: 3;
	}

	.row {
		overflow-x: hidden;
	}

	[class*='col-'] {
		background: #fff;
		position: relative;
		z-index: 2;
	}

	.col-images {
		z-index: 1;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
		overflow: hidden;

		&.is--blurred {
			filter: blur(4px);
		}
	}

	.c-pattern {
		z-index: 2;
	}

	@media ${props => props.theme.breakpoints.lg} {
		h4 {
			display: block !important;

			> div {
				padding-top: 16px;
			}
		}
	}

	@media ${props => props.theme.breakpoints.md} {
		[class*='col-'] {
			padding-bottom: 220px !important;
		}

		.col-images {
			display: none;
		}
	}

	@media ${props => props.theme.breakpoints.xs} {
		[class*='col-'] {
			padding: 1.5rem !important;
		}
	}
`;

const List = styled.ul`
	position: relative;
	padding: 1em 0;
	margin: 0;
	list-style: none outside none;

	li {
		margin-bottom: 24px;
		cursor: pointer;

		> p,
		> img {
			display: inline-block;
			vertical-align: middle;
		}

		> p {
			margin: 0;
		}

		> div {
			width: 100%;
			opacity: 0;
			visibility: hidden;
			position: absolute;
			top: 1em;
			left: 100%;
			padding: 32px 24px;
			margin-left: 3rem;
			transform: scaleX(0);
			transform-origin: 0 0;
			transition: all 0.3s ease-in-out;
			background: #fff;

			&::before {
				content: '';
				width: 48px;
				height: 48px;
				position: absolute;
				top: 0;
				right: 100%;
				opacity: 0;
				background: ${props => props.theme.colors.primary};
				transition: opacity 0.3s ease-in-out;
			}
		}

		&:hover,
		&.active {
			> p {
				color: ${props => props.theme.colors.primary};
			}
		}

		&.active {
			> div {
				opacity: 1;
				visibility: visible;
				transform: scaleX(1);

				&::before {
					opacity: 1;
				}
			}
		}

		@media ${props => props.theme.breakpoints.md} {
			font-size: 14px;

			> div {
				display: none;
				position: static;
				top: auto;
				left: auto;
				padding: 24px 0 0;
				margin: 0;

				&::before {
					content: none;
				}
			}

			&.active {
				> div {
					display: block;
				}
			}
		}
	}
`;

const Icon = styled.img`
	width: 48px;
	height: 48px;
	display: block;
	margin-right: 16px;
`;

// const EarlyBird = styled.img`
// 	width: 300px;
// 	height: 300px;
// 	position: absolute;
// 	top: 20px;
// 	right: -150px;

// 	@media ${props => props.theme.breakpoints.md} {
// 		width: 200px;
// 		height: 200px;
// 		position: absolute;
// 		top: 30px;
// 		right: 30px;
// 	}

// 	@media ${props => props.theme.breakpoints.xs} {
// 		width: 150px;
// 		height: 150px;
// 		position: absolute;
// 		top: 20px;
// 		right: 10px;
// 	}
// `;

export default ParticipationFee;
