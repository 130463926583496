import PavelInterview from 'assets/videos/pavel-bg.mp4';
import EllysInterview from 'assets/videos/ellys.mp4';
import MashupInterview from 'assets/videos/mashup.mp4';

const bg = {
	// Meta
	meta: {
		title: 'Kinetik IT Български Картинг Шампионат',
		description:
			'Станете част от Kinetik E-Series. Електрически картинг шампионат, който ще се проведе за първи път в България през юни. Предварителната регистрация е отворена.'
	},

	// Header
	header: {
		menu: {
			eGoKarts: 'Е-Картинг',
			raceCalendar: 'Състезателен календар',
			aboutUs: 'За нас',
			testimonials: 'Отзиви',
			newsletter: 'Новини',
			faq: 'FAQ'
		}
	},

	// Top section
	top: {
		title: 'Българският IT E-Картинг Шампионат',
		subTitle: {
			part1: 'Изживей ',
			part2: 'невероятното ',
			part3: 'ускорение на ',
			part4: 'електрическо състезание'
		},
		officialTest: {
			part1: 'Официален тест ',
			part2: '11.04.2022 ',
			part3: 'Кюстендил'
		},
		cta: 'Регистрирай се'
	},

	// Race calendar
	raceCal: {
		title: 'Състезателен календар',
		dateText: 'Официален тест',
		dates: '11.04.2022',
		datesUpcoming: 'Очаквайте скоро',
		location: 'Кюстендил',
		readMore: 'Научи повече',
		close: 'Затвори',
		length: 'Дължина на пистата',
		width: 'Широчина на пистата',
		address: 'Адрес',
		phone: 'Телефонен номер',
		email: 'Имейл',
		description:
			'Електрическото задвижване е бъдещето на моторните спортове, тъй като позволява скок в ефикасността и динамиката, който до скоро е бил немислим.',
		tracks: [
			{
				reverse: true,
				city: 'Варна',
				dates: '09.10.2022',
				length: '1050м',
				width: '8м - 10м',
				address: '9022 Варна, Виница, Горна трака',
				phone: '+359899901981',
				email: 'varnakarting@abv.bg'
			},
			// {
			// 	reverse: true,
			// 	city: 'Кюстендил',
			// 	dates: 'Очаквайте скоро',
			// 	length: '1050м',
			// 	width: '9м - 12м',
			// 	address: '2500 Кюстендил, ул. "Студен кладенец" 22',
			// 	phone: '+359896807806'
			// },
			// {
			// 	reverse: true,
			// 	city: 'Враца',
			// 	dates: 'Очаквайте скоро',
			// 	length: '920м',
			// 	width: '8.5м',
			// 	address: '3001 Враца, бул. "Васил Кънчов"',
			// 	phone: '+359876735244'
			// },
			{
				reverse: true,
				city: 'Хасково',
				dates: '29.10.2022',
				length: '1000м',
				width: '8.5м',
				address: '6300 Хасково, ул. "Пловдивска"',
				phone: '+359897010178',
				email: 'kartinghaskovo@gmail.com'
			},
			{
				reverse: false,
				city: 'София',
				dates: '06.11.2022',
				length: '960м',
				width: '10м',
				address: '1330 София, бул. "Вардар" 1-3',
				phone: '+359885166836',
				email: 'kartingsport@abv.bg'
			}
		]
	},

	// About the electric karts
	aboutElKarts: {
		title: 'Електрическите ни картинги',
		li1: 'Контролер с детайлни настройки на въртящия момент, регенеративно спиране и чувствителност на газта',
		li2: '40kW електрически мотор с охлаждане',
		li3: 'Специално разработена от нас телеметрия',
		li4: 'Гуми зелени и бели Vega',
		li5: 'Разработени от нас акумулаторни блокове позволяващи 50:50 разпределение на масата',
		li6: 'Шаси разработено в колаборация с Маранело Карт',
		motorPower: 'Мощност',
		motorTorque: 'Въртящ момент',
		weight: 'Тегло',
		topSpeed: 'Максимална скорост'
	},

	// Why drive an Electric Kart
	whyDrive: {
		title: 'Защо да изберете електрически картинги?',
		ans1: 'Минимална поддръжка',
		ans2: 'Пикова мощност достъпна във всички обороти',
		ans3: 'Много по-достъпна цена',
		ans4: 'Ниско шумово замърсяване',
		ans5: 'Нулеви вредни емисии',
		ans6: 'Възможност за каране на затворени и градски писти',
		p1: 'Електрическото задвижване е бъдещето на моторните спортове, тъй като позволява скок в ефикасността и динамиката, който до скоро е бил немислим. Нуждата от развъртане на двигателя за достигане на пикова мощност както и бавното ускорение са вече минало.',
		p2: 'По-голямото преимущество на електрическото задвижване е това, че позволява всички машини да имат напълно еднакво поведение на пистата. По този начин единствено уменията на пилотите и стратегията на отборите решават победителите във всяко едно състезание.'
	},

	// The team
	team: {
		title: 'Нашият екип',
		members: [
			{
				name: 'Теодосий Теодосиев',
				position: 'Изпълнителен директор и основател'
			},
			{
				name: 'Павел Лефтеров',
				position: 'Съосновател и главен тестов пилот'
			},
			{
				name: 'Антон Иванов',
				position:
					'Главен електромашинен инженер и ръководител на проекта'
			},
			{
				name: 'Лозан Лозанов',
				position: 'Мениджър събития и маркетинг'
			},
			{
				name: 'Ивайло Вълканов',
				position: 'Електронен инженер'
			},
			{
				name: 'Иван Захариев',
				position: 'Машинен инженер и отговорник производство на части'
			},
			{
				name: 'Иван Николаев',
				position: 'Софтуерен инженер'
			},
			{
				name: 'Явор Янков',
				position: 'Главен механик'
			}
		]
	},

	// Sponsors & Partners
	sponsors: {
		generalSponsor: 'Генерален спонсор',
		sponsors: 'Спонсори',
		partners: 'Участници',
		cta: 'Стани спонсор'
	},

	// About
	about: {
		title: 'За нас',
		text1: 'Ние от Кинетик Аутомотив създаваме бутикови електрически превозни средства и предлагаме високотехнологични решения на компании от автомобилната индустрия. Като автомобилни ентусиасти и фенове на електрическите автомобили искахме да приложим знанията и опита си, за да ускорим прехода към електрическо задвижване в моторните спортове.',
		text2: 'Да караш Kinetik E Go-Kart е изживяване, което не е за пропускане. С моменталния си въртящ момент машината ускорява от 0 до 100 за 3,5 секунди, докато си на едва 2 сантиметра над земята. KES IT шампионатът е идеален източник на адреналин и усещане за бързи скорости. Това не само ще ти даде чувството за свобода, но и шанса на компанията ти да блесне пред останалите.'
	},

	// Sign up
	signUp: {
		title: 'Абонирай се за последните новини',
		subtitle:
			'Искаш пръв да научаваш последните новини за IT Българския Картинг Шампионат?',
		text: 'Попълни формата и се запиши за нашия информационен бюлетин! Ще получаваш персонализирани имейли с важни известия, както и кадри от тестовете, които провеждаме преди началото на сезона.',
		formTitle: 'Информационен бюлетин',
		formSuccess: 'Успешна регистрация!',
		formSending: 'Изпращане',
		formFieldName: 'Име',
		formFieldEmail: 'Имейл',
		formBtn: 'Абонирай се'
	},

	// Frequently Asked Questions
	faq: {
		title: 'Често Задавани Въпроси',
		items: [
			{
				q: 'Кога ще се състои картинг шампионатът?',
				a: 'Датите за официалните състезания от шампионата, общо 3 на брой, ще бъдат 9ти октомври (Варна), 29ти октомври (Хасково) и 6ти Ноември (София).'
			},
			{
				q: 'Какъв е броят на участниците в шампионата?',
				a: 'Общият брой състезатели за сезон 2022 е 10. Местата са ограничени, затова можете да заявите желанието си за участие възможно най-скоро, като попълните.'
			},
			{
				q: 'Колко ще бъдат състезанията?',
				a: 'Шампионатът се състои от 3 състезания на открито, на писти, които предлагат добра комбинация между висока скорост и технични сектори. Пистите се намират в градовете Варна, Хасково и София.'
			},
			{
				q: 'Кой ще осигури електрическите картинги за шампионата?',
				a: 'Електрическите картинги ще бъдат осигурени от Kinetik Automotive. Те са създадени по модел на първият електрически карт на компанията - Kinetik 27.'
			},
			{
				q: 'Как мога да се запиша за участие в картинг шампионата?',
				a: 'За да се запишете за участие в шампионата е необходимо да попълните регистрационната форма. След успешна регистрация, екипът ни ще оцени кандидатите и ще се свърже с тези, които отговарят на всички критерии за участие и са избрани да се включат в шампионата. Заявките за участие ще бъдат разглеждани спрямо датата на регистрация.'
			}
		]
	},

	// Register
	register: {
		registerBtn: 'Регистрация',
		formTitle: 'Регистрация',
		formDesc: 'Всички полета са задължителни',
		formSuccess: 'Успешна регистрация',
		formSending: 'Изпращане',
		formFieldFirstName: 'Име',
		formFieldLastName: 'Фамилия',
		formFieldEmail: 'Имейл',
		formFieldPhoneNumber: 'Телефон',
		formFieldCountry: 'Компания',
		formFieldAge: 'Възраст',
		formFieldKartingLicense: 'Притежаваш ли картинг лиценз?',
		formFieldDriveType: 'За какво искаш да се запишеш?',
		formFieldYes: 'Да',
		formFieldNo: 'Не',
		formBtn: 'Изпрати',
		driveTypes: {
			testDriveOnly: 'Само за тестово каране (такса € 250)',
			championshipOnly:
				'Само за шампионата (такса за целия шампионат € 2,870)',
			both: 'За тестово каране и за шампионата'
		}
	},

	footer: {
		text: 'Kinetik IT Български Картинг Шампионат'
	},

	// Testimonials
	testimonials: {
		title: 'Какво хората казват за нас',
		subtitle:
			'Чуйте какво имат да кажат за Кинетик Картс някои от поддръжниците ни.',
		prevButton: 'Предишен',
		nextButton: 'Следващ',
		playButton: 'Гледай',
		items: [
			{
				quote: [
					'Павел Лефтеров е български професионален автомобилен състезател с редица титли както в картинг спорта, така и в GT сериите, където се състезава и в момента. Той е главният тестов пилот на Кинетик Картс и един от водещите експерти, консултиращи екипа ни.'
				],
				video: PavelInterview
			},
			{
				quote: [
					'Елис Спиеция е американски картинг състезател и един от първите пилоти избрали да развиват кариера единствено и само в електрическите моторни спортове. Също така е и единственият човек в света тествал всички електрически състезателни картинги на пазара.'
				],
				video: EllysInterview
			},
			{
				quote: [
					'Това са хората, с които сме се запознали по време на нашите открити тестове и медийни събития. Те са имали възможността да изпробват нашите картинги и спокойно можем да кажем, че се влюбиха в тях от първото каране. Чуйте какво имат да споделят.'
				],
				video: MashupInterview
			}
		]
	},

	// Test Drive
	testDrive: {
		title: 'Състезавай се и спечели *RS1 BLACK* Racing Simulator Cage',
		subtitle: 'Включи се в шампионата Днес!',
		text: 'Стани един от 10 състезатели в IT Българския Картинг Шампионат',
		buttonText: 'Регистрирай се!',
		driver: 'Rseat RS1 Black'
	},

	// Participation Fee
	participationFee: {
		title: 'Такса за участие',
		subtitle: 'Какво покрива таксата участие',
		content:
			'Всеки кръг от шампионата ще бъде провеждан в рамките на посочени уикенди и ще включва  Тренировъчни Сесии, 1 Квалификационна Сесия, Полуфинал и Финал. Сесиите ще са с продължителност между 10 и 15 минути, като всички екипи ще получат два комплекта гуми Vega, пълен достъп до данните от телеметрията, отделна палатка в пит лентата и екип от квалифицирани механици. Всичко, което се изисква от пилотите е да донесат своите състезателни екипи и да бъдат готови за ожесточено състезание!',
		fee: {
			amount: '€ 1,900',
			description: 'за уикенд'
		},
		total: {
			amount: '€ 2,870',
			description: 'за целия шампионат'
		},
		features: [
			{
				title: 'Достъпност и равен шанс',
				content: [
					'Целта на Kinetik E-Series е да играе ролята на трамплин за кариерите на млади и талантливи пилоти както и да направи картинг спорта възможно най-достъпен. Електрическото задвижване е ключът към постигането на тези цели, тъй като е много по-надеждно при далеч по-ниска поддръжка. Също така произвежда една и съща мощност, гарантирайки еднаквата динамика на всички картинги на пистата.'
				]
			},
			{
				title: 'Arrive and Drive',
				content: [
					'Желанието ни е шампионатът да бъде в Arrive and Drive формат, правейки участието възможно най-лесно. Цялата логистика по време на състезателните уикенди, както и между тях, ще бъде наша отговорност. Единственото, от което ще се нуждаете е състезателната ви екипировка, личен транспорт до пистата и хотел, в който да отседнете. Нашият екип е на ваше разположение, в готовност да помогне с организирането на престоя ви.'
				]
			},
			{
				title: 'Екип от механици',
				content: [
					'Нашият екип от механици ще бъде на ваше разположение по време на всички тренировки, квалификации и състезания. Те са идеално запознати с нашите картинги и ще ги поддържат и настройват спрямо вашите персонални предпочитания.'
				]
			},
			{
				title: 'Пълен достъп до телеметричните данни',
				content: [
					'За разлика от повечето електрически шампионати, ние твърдо заставаме зад идеята, че пилотите трябва да имат пълен достъп до телеметричните данни записани по време на състезателните уикенди. За нас е изключително важно да можем да предоставим цялата информация, нужна на пилотите и техните екипи, за да успяват да подобряват представянето си на пистата и да записват възможно най-бързи времена.'
				]
			},
			{
				title: 'Гуми и джанти',
				content: [
					'Официалната гума, използвана във всички събития от Kinetik E Series ще бъде Vega. За всеки състезателен уикенд ще ви бъдат предоставени два сета сликове, като сместа на гумата ще зависи от пистата, на която ще се провеждат състезанията. Също така ще имате достъп и до един комплект използвани гуми от предишен кръг. За гумите ще ви бъде осигурен и един комплект джанти. Ако решите, че се нуждаете от допълнително гуми или джанти, ще имате възможността да ги закупите по време на всеки уикенд.'
				]
			},
			{
				title: 'Палатка',
				content: [
					'Когато пристигнете на пистата, ще намерите вашата палатка вече разпъната, а в нея ще ви очаква картингът ви заедно с всички джанти и гуми, напълно подготвен за каране. В палатката също така ще намерите бутилки с минерална вода, храна, столове, маса разклонител, в който може да зареждате вашите мобилни устройства и компютри. В сервизната зона ще имате достъп и до високоскоростна интернет мрежа.'
				]
			}
		],
		testDrive: {
			buttonText: 'Регистрация'
		}
	}
};

export default bg;
