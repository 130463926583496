import React, { useContext } from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';

// import Button from 'components/Button';
import Pattern from 'components/Pattern';
import { LanguageContext } from 'components/LanguageContext';

import nexoLogo from 'assets/partners/nexo-logo.svg';

const sponsorsList = [
	{
		alt: 'Nexo logo',
		src: nexoLogo,
		href: 'https://nexo.io/',
		isHigh: true
	}
];

const partnersList = [];

const Sponsors = () => {
	const {
		dictionary: { sponsors }
	} = useContext(LanguageContext);

	const List = ({ items, title }) => (
		<>
			<Heading className="pt-3 pt-lg-5">{title}</Heading>

			<div className="c-sponsors-list">
				{items.map(({ alt, src, href, isHigh }, index) => (
					<a
						key={index}
						rel="noopener noreferrer"
						href={href}
						target="_blank"
						className="d-inline-block m-4"
					>
						<img
							src={src}
							alt={alt}
							className={`img-fluid${isHigh ? ' img-high' : ''}`}
						/>
					</a>
				))}
			</div>
		</>
	);

	return (
		<SponsorsSection>
			<Container fluid className="p-5">
				<List title={sponsors.sponsors} items={sponsorsList} />
				<List title={sponsors.partners} items={partnersList} />

				{/* <Button
					rel="noopener noreferrer"
					href="mailto:info@kinetik-e.com"
					target="_blank"
					className="btn mt-5 mb-3 mb-lg-5 btn--hover-shadow-white"
				>
					{sponsors.cta}
				</Button> */}
			</Container>

			<Pattern x="left" y="top" name="SponsorsTopRight" />
		</SponsorsSection>
	);
};

const SponsorsSection = styled.section`
	background-color: #000;
	position: relative;

	.container-fluid {
		text-align: center;
		max-width: 1024px;
	}

	.c-sponsors-list {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;

		a,
		img {
			display: block;
		}
	}

	img {
		width: auto;
		height: 26px;
		display: block;

		&.img-high {
			height: 60px;
		}
	}
`;

const Heading = styled.h2`
	color: #fff;
	opacity: 0.5;
`;

export default Sponsors;
