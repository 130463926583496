export const decorateText = (text, tag = 'strong') => {
	const selfClosingTag = [
		'br',
		'hr',
		'col',
		'img',
		'wbr',
		'area',
		'base',
		'link',
		'meta',
		'embed',
		'input',
		'param',
		'track',
		'keygen',
		'source',
		'command',
		'menuitem'
	];

	return text.replace(
		/\*([^*]+)\*/g,
		selfClosingTag.includes(tag) ? `<${tag}/>` : `<${tag}>$1</${tag}>`
	);
};
