import PavelInterview from 'assets/videos/pavel-en.mp4';
import EllysInterview from 'assets/videos/ellys.mp4';
import MashupInterview from 'assets/videos/mashup.mp4';

const en = {
	// Meta
	meta: {
		title: 'Kinetik Bulgarian IT e-GO Kart Championship',
		description:
			'Join the Kinetk E-Series which kick off in June 2022 in Bulgaria. Pre-registration is now open.'
	},

	// Header
	header: {
		menu: {
			eGoKarts: 'eGo Karts',
			raceCalendar: 'Race Calendar',
			aboutUs: 'About Us',
			testimonials: 'Testimonials',
			newsletter: 'Newsletter',
			faq: 'FAQ'
		}
	},

	// Top section
	top: {
		title: 'The Bulgarian IT e-GO Kart Championship',
		subTitle: {
			part1: 'Experience the ',
			part2: 'High-Speed ',
			part3: 'Thrills of ',
			part4: 'E-racing'
		},
		officialTest: {
			part1: 'Pre-season Test ',
			part2: '11.04.2022 ',
			part3: 'Kyustendil'
		},
		cta: 'Register'
	},

	// Race calendar
	raceCal: {
		title: 'Race Calendar',
		dateText: 'Pre-season Test',
		dates: '11.04.2022',
		datesUpcoming: 'To be confirmed',
		location: 'Kyustendil',
		readMore: 'Read more',
		close: 'Close',
		length: 'Track Length',
		width: 'Track Width',
		address: 'Address',
		phone: 'Phone Number',
		email: 'Email',
		description:
			'Electric is the future, and there is no getting around that. The leap forward in terms of drivetrain efficiency has allowed for performance that has until now been utterly unattainable.',
		tracks: [
			{
				reverse: true,
				city: 'Varna',
				dates: '09.10.2022',
				length: '1050m',
				width: '8m - 10m',
				address: 'Vinitsa, Gorna Traka, Varna 9022',
				phone: '+359899901981',
				email: 'varnakarting@abv.bg'
			},
			// {
			// 	reverse: true,
			// 	city: 'Kyustendil',
			// 	dates: 'To be confirmed',
			// 	length: '1050m',
			// 	width: '9m - 12m',
			// 	address: 'Studen Kladenets Str. 22, Kyustendil 2500',
			// 	phone: '+359896807806'
			// },
			// {
			// 	reverse: true,
			// 	city: 'Vratsa',
			// 	dates: 'To be confirmed',
			// 	length: '920m',
			// 	width: '8.5m',
			// 	address: 'Vasil Kanchov blvd., Vratsa 3001',
			// 	phone: '+359876735244'
			// },
			{
				reverse: true,
				city: 'Haskovo',
				dates: '29.10.2022',
				length: '1000m',
				width: '8.5m',
				address: 'Plovdivska Str., Haskovo 6300',
				phone: '+359897010178',
				email: 'kartinghaskovo@gmail.com'
			},
			{
				reverse: false,
				city: 'Sofia',
				dates: '06.11.2022',
				length: '960m',
				width: '10m',
				address: 'Vardar blvd. 1-3, Sofia 1330',
				phone: '+359885166836',
				email: 'kartingsport@abv.bg'
			}
		]
	},

	// About the electric karts
	aboutElKarts: {
		title: 'About the Electric Karts',
		li1: 'Controller With Advanced Settings For Torque Maps, Regenerative Braking, TPS',
		li2: '40kW Electric Motor With Custom Cooling',
		li3: 'In-House Developed Telemetry With Data Acquisition System',
		li4: 'Vega White & Green Slick Tires',
		li5: 'In-House Developed Battery Pack Modules For Perfect 50:50 Balance',
		li6: 'Custom Chassis Developed In Collaboration With Maranello',
		motorPower: 'Motor Power',
		motorTorque: 'Motor Torque',
		weight: 'Weight',
		topSpeed: 'Top Speed'
	},

	// Why drive an Electric Kart
	whyDrive: {
		title: 'Why drive an Electric Kart?',
		ans1: 'Little to No Maintanance',
		ans2: 'Full Torque Delivery From 0 RPM',
		ans3: 'Much Greater Affordability',
		ans4: 'Lower Noise Pollution',
		ans5: 'Zero CO2 Emissions',
		ans6: 'Indoor Tracks are Now an Option',
		p1: 'Electric is the future, and there is no getting around that. The leap forward in terms of drivetrain efficiency has allowed for performance that has until now been utterly unattainable. Slower engine response and sluggish acceleration are a thing of the past! The future is rapid!',
		p2: "But what's even better is that electric power allows a completely level playing field for all drivers. Regardless of how much money your opponents have, you are guaranteed to have 100% the same performance as them. Skill and strategy are all that matters now!"
	},

	// The team
	team: {
		title: 'The Team',
		members: [
			{
				name: 'Teodosy Teodosiev',
				position: 'Founder'
			},
			{
				name: 'Pavel Lefterov',
				position: 'Co-founder and Test Pilot'
			},
			{
				name: 'Anton Ivanov',
				position: 'Chief Electro-mechanical Engineer'
			},
			{
				name: 'Lozan Lozanov',
				position: 'Event and Marketing Manager'
			},
			{
				name: 'Ivaylo Valkanov',
				position: 'Electronics Engineer'
			},
			{
				name: 'Ivan Zahariev',
				position: 'Mechanical Engineer and Lead Manufacturing Parts'
			},
			{
				name: 'Ivan Nikolaev',
				position: 'Software Engineer'
			},
			{
				name: 'Yavor Yankov',
				position: 'Lead Mechanical Technician'
			}
		]
	},

	// Sponsors & Partners
	sponsors: {
		generalSponsor: 'General Sponsor',
		sponsors: 'Sponsors',
		partners: 'Participants',
		cta: 'Become a Sponsor'
	},

	// About
	about: {
		title: 'About us',
		text1: 'We at Kinetik Automotive create boutique electric vehicles and provide high-tech solutions to companies in the automotive industry. As we are all car guys and EV lovers at heart, we wanted to use our knowledge and experience to help accelerate the transition to electric propulsion in motorsports.',
		text2: 'Our Kinetik E Go-Kart is an experience that should not be missed. With its instant torque, the machine accelerates from 0 to 100 for 3.5 seconds while you are 2 centimeters above the ground. The KES championship is an ideal source of adrenaline, a feeling of fast speeds and control of the moment. It will not only give you the sense of freedom but also give your company the chance to shine before the others.'
	},

	// Sign up
	signUp: {
		title: 'Get all the latest updates',
		subtitle:
			'Want to stay up to date with the latest Kinetik Bulgarian IT e-GO Kart Championship news?',
		text: 'Just fill in the form to sign up for our newsletter! You will receive important announcements and behind the scenes footage from our preseason testing events.',
		formTitle: 'Newsletter',
		formSuccess: 'Success!',
		formSending: 'Sending...',
		formFieldName: 'Name',
		formFieldEmail: 'Email',
		formBtn: 'Subscribe'
	},

	// Frequently Asked Questions
	faq: {
		title: 'Frequently Asked Questions',
		items: [
			{
				q: 'When will the Kinetik Bulgarian IT e-GO Kart Championship take place?',
				a: 'The dates for the three race days of the Kinetik Bulgarian IT e-GO Kart Championship will be the 9th of October (Varna), 29th of October (Haskovo) and 06th of November (Sofia).'
			},
			{
				q: 'How many drivers will participate in the Kinetik Bulgarian IT e-GO Kart Championship?',
				a: 'We have a total of ten seats available to drivers for the 2022 season of the championship and they will be filling up quickly. So make sure you get yours as early as possible, if you want to be taking part in the races.'
			},
			{
				q: 'How many races will there be?',
				a: 'We are going to have in total of three races, all of which will be held on outdoor tracks. The tracks that we’ve selected are located in Varna, Haskovo and Sofia as they offer a great mix of both high speed and technical sections. The dates for the three races of the Kinetik Bulgarian IT e-GO Kart Championship will be the 9th of October (Varna), 29th of October (Haskovo) and 06th of November (Sofia).'
			},
			{
				q: 'Who will supply the go-karts for the Kinetik Bulgarian IT e-GO Kart Championship?',
				a: 'The go-karts for Kinetik Bulgarian IT e-GO Kart Championship will be supplied by Kinetik Automotive, hence the name. They are the performance variant of the company’s first electric kart - the Kinetik 27.'
			},
			{
				q: 'How can I sign up for the Kinetik Bulgarian IT e-GO Kart Championship?',
				a: 'To sign up for the Kinetik Bulgarian IT e-GO Kart Championship you need to fill in the pre-registration form. Once you have successfully submitted it, our team will evaluate the candidates and come back to those who meet the requirements. Naturally, the application will be reviewed in the order they were sent.'
			}
		]
	},

	// Register
	register: {
		registerBtn: 'Register',
		formTitle: 'Registration',
		formDesc: 'All fields are mandatory',
		formSuccess: 'Success!',
		formSending: 'Sending...',
		formFieldFirstName: 'First Name',
		formFieldLastName: 'Last Name',
		formFieldEmail: 'Email Address',
		formFieldPhoneNumber: 'Phone Number',
		formFieldCountry: 'Company',
		formFieldAge: 'Age',
		formFieldKartingLicense: 'Do you own a Karting License?',
		formFieldDriveType: 'What would you like to apply for?',
		formFieldYes: 'Yes',
		formFieldNo: 'No',
		formBtn: 'Submit',
		driveTypes: {
			testDriveOnly: 'A test drive only (€250 fee)',
			championshipOnly:
				'A championship entry only (€14,500 fee for the complete series)',
			both: 'Both test drive and championship'
		}
	},

	footer: {
		text: 'Kinetik Bulgarian IT e-GO Kart Championship'
	},

	// Testimonials
	testimonials: {
		title: 'What Other People Say About Us',
		subtitle:
			'Listen to what some of our supporters have to say about their experience with Kinetik Karts.',
		prevButton: 'Prev',
		nextButton: 'Next',
		playButton: 'Play',
		items: [
			{
				quote: [
					'Pavel Lefterov is a Bulgarian professional racing driver who has won numerous titles both in karting and later on in the GT Series that he is currently a part of. He is the chief test pilot at Kinetik Karts and one of our leading experts, helping our team.'
				],
				video: PavelInterview
			},
			{
				quote: [
					'Ellis Spiezia is an American electric karting driver and one of the very first pilots who have decided to pursue a career in electric racing only. He is also the only driver to have tested every performance electric go-kart on the market.'
				],
				video: EllysInterview
			},
			{
				quote: [
					"People who we've met during our open testing and media events. They are amongst the few who have experienced our machines and we can easily say that it has been love at first-ride! Every ride! Here is what some of them had to say about it."
				],
				video: MashupInterview
			}
		]
	},

	// Test Drive
	testDrive: {
		title: 'Race To Win! *RS1 BLACK* Racing Simulator Cage',
		subtitle: 'Join The Championship Today',
		text: 'Become one of the 10 competitors in the Kinetik Electric Karting Series',
		buttonText: 'Register',
		driver: 'Rseat RS1 Black'
	},

	// Participation Fee
	participationFee: {
		title: 'Participation Fee',
		subtitle: "What's Included in The Participation Fee",
		content:
			'Each round consists 8 Training Sessions, 1 Qualifying Session, а Semi-Final, and a Final taking place over the announced weekends. All sessions will be between 15 and 20 minutes long, and teams will receive two sets of Vega tires, full access to telemetry data, a dedicated service tent in the pit area and a team of qualified mechanics. All that is required from the drivers is to bring their own protective gear and be ready to race.',
		fee: {
			amount: '€ 1,900',
			description: 'Per Race'
		},
		total: {
			amount: '€ 2,870',
			description: 'For The Complete Series'
		},
		features: [
			{
				title: 'Affordability and Equal Playing Field',
				content: [
					'Our aspiration for the Kinetik E-Series is to help accelerate the careers of young and talented drivers while also making the sport as affordable as possible. This is where electric karts shine. The electric drivetrain is not only far more reliable, requiring considerably lower maintenance, but is also producing a consistent power output, thus guaranteeing an equal playing field for all drivers.'
				]
			},
			{
				title: 'Arrive and Drive',
				content: [
					'As you can see, we are keen on providing a complete Arrive and Drive service by making it as hassle free for you as possible. All you’ll need in order to take part in the Kinetik E-Series is your racing gear, a plane ticket and a hotel to stay. We can even take care of the latter two to make things more straightforward for you.'
				]
			},
			{
				title: 'A Team of Mechanics',
				content: [
					'Our team of mechanics will be at your disposal during all training sessions, qually and racing. Knowing the ins and outs of our go-karts, we will maintain and set them up according to your personal preferences.'
				]
			},
			{
				title: 'Full Access to Telemetry Data',
				content: [
					'Unlike most electric karting championships, we firmly believe that drivers should have full access to all telemetry data recorded during the race weekends. We aim to provide you with all the necessary information that you may need to improve your performance on the track and be as quick as possible.'
				]
			},
			{
				title: 'Wheels and Tyres',
				content: [
					'The official tyre for the Kinetik Electric Series is Vega. Each race weekend, you will have two sets of either white or green Vegas waiting for you on the track, as well as up to one set of used tyres from your previous race. You will also be given a single set of wheels. However, if you decide you need a second, you will have the option to purchase one either before the race or during the event itself. The same applies to dry and wet weather tyres, as we will have a supplier on every track.'
				]
			},
			{
				title: 'Pit Tent',
				content: [
					'Upon arriving at the track, there will be a tent set up for you in the pit lane, with your kart, wheels and tyres all ready to go. In the tent, you will also find bottles of water, some snacks, chairs, a table and electrical sockets where you can charge laptops, phones and other devices. Across all areas of the pit lane, we will have set up a high-speed WIFI network for all guests, drivers and their teams. '
				]
			}
		],
		testDrive: {
			buttonText: 'Register'
		}
	}
};

export default en;
