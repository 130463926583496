import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import RegCustom from './RegCustom';

const RegForm = ({ testDriveOverwrite }) => (
	<div className="mc__form-container">
		<MailchimpSubscribe
			url="https://kinetikautomotive.us12.list-manage.com/subscribe/post-json?u=699d46054de9a8ddfbdbbe8dc&id=afd54a834c"
			render={({ subscribe, status, message }) => (
				<RegCustom
					status={status}
					message={message}
					onValidated={formData => subscribe(formData)}
					testDriveOverwrite={testDriveOverwrite}
				/>
			)}
		/>
	</div>
);

export default RegForm;
