import React, { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';

import RegForm from 'routes/home/RegForm';
import { decorateText } from 'utils/helpers';

import CModal from 'components/Modal';
import Button from 'components/Button';
import Pattern from 'components/Pattern';
import { LanguageContext } from 'components/LanguageContext';

import Prize from 'assets/test-drive/prize.jpg';
import PartnerLogo from 'assets/test-drive/rseat-logo.png';
import TestDriveBg from 'assets/test-drive/bg-rseat.jpg';

const TestDrive = () => {
	const {
		dictionary: { testDrive }
	} = useContext(LanguageContext);

	return (
		<TestDriveSection id="test-drive">
			<Pattern x="left" y="top" name="TestDriveTopLeft" />

			<Pattern x="left" y="bottom" name="TestDriveBottomLeft" />

			<Container fluid className="pt-2">
				<Row>
					<Col sm={12} md={5} className="g-md-0 mt-5 mb-5">
						<Fade direction="right">
							<ContentWrap className="p-5 text-white">
								<div className="test-drive-partner">
									<img
										src={PartnerLogo}
										alt="ERA brand graphic"
									/>
								</div>

								<h2
									dangerouslySetInnerHTML={{
										__html: decorateText(testDrive.title)
									}}
								/>

								<DriverInfo className="c-driver">
									<img src={Prize} alt={testDrive.driver} />

									<figcaption>{testDrive.driver}</figcaption>

									<Pattern
										x="right"
										y="bottom"
										name="TestDriveFrame"
									/>
								</DriverInfo>

								<h3>{testDrive.subtitle}</h3>

								<p>{testDrive.text}</p>

								<CModal
									content={<RegForm />}
									className="c-modal--reg"
								>
									<Button
										variant="primary"
										className="mt-4 text-uppercase btn--hover-shadow-white"
									>
										{testDrive.buttonText}
									</Button>
								</CModal>
							</ContentWrap>
						</Fade>
					</Col>
				</Row>
			</Container>
		</TestDriveSection>
	);
};

const TestDriveSection = styled.section`
	position: relative;
	background: url(${TestDriveBg}) no-repeat center center / cover;

	.row {
		justify-content: flex-end;
		overflow-x: hidden;

		> div {
			flex: 0 0 540px;
			width: 540px;
		}
	}

	@media ${props => props.theme.breakpoints.xs} {
		.row {
			padding: 1rem;

			> div {
				width: 100%;
				flex: 1;
				margin: 1.5rem 0 !important;
			}
		}
	}
`;

const ContentWrap = styled.div`
	position: relative;
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
	background-size: 50%;

	h2 strong,
	h3 {
		color: ${props => props.theme.colors.primary};
	}

	h2 strong {
		display: block;
		font-weight: normal;
	}

	.test-drive-partner {
		position: absolute;
		top: 0;
		right: 100%;
		padding: 20px 20px 10px;
		background-color: rgba(0, 0, 0, 0.5);

		img {
			display: block;
		}
	}

	@media ${props => props.theme.breakpoints.xs} {
		padding: 1.5rem !important;

		.test-drive-partner {
			position: static;
			padding: 0;
			background: none;
			margin: 0 0 1rem;
		}

		.btn {
			margin-top: 0.5rem !important;
		}
	}
`;

const DriverInfo = styled.figure`
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	position: relative;
	margin-bottom: 32px;

	img {
		max-width: 200px;
		height: auto;
		display: block;
	}

	.c-pattern {
		display: block;
		top: 59px;
	}

	figcaption {
		font-size: 18px;
		color: #000;
		flex: 1;
		padding: 16px 48px 16px 16px;
		background-color: ${props => props.theme.colors.primary};
	}

	@media ${props => props.theme.breakpoints.xs} {
		display: block;

		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}
`;

export default TestDrive;
